<template>
    <el-row :gutter="160">      
        <el-col :span="2">
            <el-button type="primary" icon="el-icon-plus" @click="onClickAdd">新增跨服聊天组</el-button>
        </el-col>
        <el-col :span="24">
            <el-table ref="multipleTable" :data="showList" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
                :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="分组ID"> </el-table-column>
                <el-table-column prop="name" label="分组名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="default_server" label="默认区服" show-overflow-tooltip></el-table-column>                
                <el-table-column prop="amount" label="区服数量" show-overflow-tooltip></el-table-column>
                <el-table-column prop="update_time" label="更新时间" show-overflow-tooltip></el-table-column>            
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEdit(scope.row)" type="primary"
                            icon="el-icon-edit" size="small">编辑</el-button>
                        <el-button @click.native.prevent="onClickDelete(scope.row)" type="danger"
                            icon="el-icon-delete" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
        <!-- ========弹框BEGIN====== -->
        <el-dialog :title="formTitle" :visible.sync="dialogForm" width="30%" :close-on-click-modal="false" center class="formparams">
            <el-form :model="form" :label-width="formLabelWidth">
                <el-form-item label="分组名称" >
                    <el-input v-model="form.name" placeholder="输入名称"></el-input>
                </el-form-item>                 
                <el-form-item label="选择包含的区服">
                    <el-button type="primary" icon="el-icon-menu" @click="onSelectItem">区服</el-button>                   
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onAddShowList">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
		<!-- ========物品弹框====== -->
        <el-dialog title="服务器选择" :visible.sync="dialogItemForm" width="70%" :close-on-click-modal="false" :show-close="false" center class="itemdialog">
        <div style="width: 100%">
            <el-row>
              <el-col :span="4">
                    <el-select v-model="form.group" placeholder="服务器分组" value-key="id" @change="onFormSelectGroup" style="width:100%;">
                        <el-option v-for="item in serverGroups" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
              </el-col>
              <el-col :span="6">
                  <el-input v-model="search" placeholder="关键字搜索" style="width:100%;"></el-input>
              </el-col>              
              <el-col :span="4">
                  <el-button type="success" plain style="width:100%;" disabled>&gt;&gt;&gt;&gt;&gt;&gt;</el-button>
              </el-col>
              <el-col :span="10">
                  <el-button type="primary" style="width:100%;" disabled>已选择的服务器</el-button>
              </el-col>
            </el-row>
            <el-row :gutter="0">
              <el-col :span="10">
                  <el-table :data="filterItems" border style="width: 100%" height="420" class="serverlist" @selection-change="leftSelectionChange">
                    <el-table-column label="ID" prop="id" type="selection"></el-table-column>
                    <el-table-column label="servername" prop="name" show-overflow-tooltip></el-table-column>
                    <el-table-column label="显示名称" prop="title" show-overflow-tooltip></el-table-column>                              
                    <el-table-column label="操作" >
                      <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="onAddItem([scope.row])">添加</el-button>
                      </template>
                    </el-table-column>
                  </el-table>               
              </el-col>
              <el-col :span="4">
                  <el-row>
                      <el-button size="mini" type="primary" @click="onAddItem(null)" style="margin-top:180px;">批量&gt;&gt;添加</el-button>
                  </el-row>
                  <el-row>
                      <el-button size="mini" type="danger" @click="onRemoveItem(null)">批量&lt;&lt;移除</el-button>
                  </el-row>
              </el-col>
              <el-col :span="10">
                  <el-table :data="selectedServers" border style="width: 100%" height="420" class="serverlist" @selection-change="rightSelectionChange">
                    <el-table-column label="ID" prop="id" type="selection" ></el-table-column>
                    <el-table-column label="servername" prop="name" show-overflow-tooltip></el-table-column>
                    <el-table-column label="显示名称" prop="title" show-overflow-tooltip></el-table-column>                    
                    <el-table-column label="操作">
                      <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="onRemoveItem([scope.row])" style="float:right;">移除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>                
              </el-col>
            </el-row>            
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" icon="el-icon-check" @click="dialogItemForm=false">确 定</el-button>
        </div>
        </el-dialog>
		<!-- ========弹框END====== -->
    </el-row>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    export default {
        data() {
            return {
                showList: [],
                formTitle: '',
                dialogForm: false,
                dialogItemForm: false,
                form: {
                    id: '',
                    name: '',
                    group: '',
                },
                search: '',
                servers: [],// 可选的区服
                selectedServers: [], // 已选择的区服
                leftSelectRows: [], // 左边选择的项
                rightSelectRows: [], // 右边选择的项
                serverGroups: [],                
                formLabelWidth: '130px',
                tableHeight: document.documentElement.clientHeight-136,
            }
        },
        created: function() {
            this.refreshShowList()
            this.refreshGroupList()
        },
        computed: {
            ...mapGetters(
                ['gameHttp']
            ),
            // 物品关键词过滤显示
            filterItems() {
                return this.servers.filter((data)=>{
                    // 已选择过的则不用再显示了
                    for (let i=0; i<this.selectedServers.length; i++) {
                        if (data.id == this.selectedServers[i].id) {
                            return false;
                        }
                    }
                    // 没有搜索条件 或 包含搜索字则显示
                    return !this.search || (data.name.includes(this.search) || data.title.includes(this.search));
                })
            },
        },
        methods: {
            // 刷新分组
            refreshShowList() {
                this.gameHttp(1061, {
                    groupid: this.curGroupId
                }).then((response) => {
                    this.showList = response.data.list
                })
            },
            // 刷新分组分组
            refreshGroupList() {
                this.gameHttp(1003, {plain:1}).then((response) => {
                    this.serverGroups = response.data.list
                    this.serverGroups.splice(0, 0, { id: -1, name: "全部分组"})
                })
            },
            // 弹出新增编辑框
            onClickAdd() {
                this.formTitle = '新增区服显示分组'
                this.dialogForm = true
                this.servers = []
                this.selectedServers = []
                this.leftSelectRows = []
                this.rightSelectRows = []
                for (let k in this.form) {
                    this.form[k] = ''
                }
            },
            // 弹出修改编辑框
            onClickEdit(row) {
                this.formTitle = '修改区服显示分组'
                this.dialogForm = true
                this.servers = []
                this.selectedServers = JSON.parse(JSON.stringify(row.servers))
                this.leftSelectRows = []
                this.rightSelectRows = []
                this.form.id = row.id
                this.form.name = row.name
            },            
            // 弹出选择服务器
            onSelectItem() {
                this.dialogItemForm = true
            },
            // 表单选择分组
            onFormSelectGroup(id) {
                this.gameHttp(1001, { // 拉取服务器分组
                    groupid: id,
                    plain: 1,
                }).then((response) => {
                    this.servers = response.data.list
                })
            },
            // 处理左边多选变化
			leftSelectionChange(rows) {
                this.leftSelectRows = rows;
			},
            // 处理右边多选变化
			rightSelectionChange(rows) {
                this.rightSelectRows = rows;
			},            
            // 添加项到选择列
            onAddItem(rows) {
                if (!rows) {
                    rows = this.leftSelectRows;
                }
                for (let i=0; i<rows.length; i++) {
                    let item = JSON.parse( JSON.stringify(rows[i]) );
                    item.default = false; // 默认不选
                    this.selectedServers.push(item);
                }
            },
            // 移除选择的项
            onRemoveItem(rows) {
                if (!rows) {
                    rows = this.rightSelectRows;
                }
                let funcRomove = (row) => {
                    for (let i=0; i<this.selectedServers.length; i++) {
                        if (row.id == this.selectedServers[i].id) {
                            this.selectedServers.splice(i, 1);
                            // 左边分组不存在的则压入到左边
                            for (let j=0; j<this.servers.length; j++) {
                                if (row.id == this.servers[j].id) {
                                    return;
                                }
                            }
                            this.servers.push(row);
                            break;
                        }
                    } 
                };
                // 判断默认区服不能移除
                for (let r=0; r<rows.length; r++) {
                    if(rows[r].default) {
                        this.$message.error("不可移除默认区服:"+rows[r].name);
                        return;
                    }
                }
                for (let r=0; r<rows.length; r++) {
                    funcRomove(rows[r]);
                }
            },

            // 添加新分组
            onAddShowList() {
                if (!this.form.name) {
                    this.$message.error('请输入分组名称');
                    return;
                }                
                this.dialogForm = false
                let serverids = [];
                for (let i=0; i<this.selectedServers.length; i++) {
                    let item = this.selectedServers[i];
                    serverids.push( item.id );
                }
                let list = {
                    id: this.form.id,
                    name: this.form.name,
                    serverids: serverids
                };
                console.log(list)
                // 请求操作
                this.gameHttp(1062, list).then((response) => {
                    if (0 == response.data.errcode) {
                        this.refreshShowList()
                        this.$message.success("提交成功")
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },
            // 显示分组的删除
            onClickDelete(row) {
                this.$confirm('是否删除[ ' + row.name + ' ]分组?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(1063, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("删除成功")
                            this.refreshShowList()
                        }
                    })
                }).catch(() => {})
            },
            // 数据表色彩间隔效果     
            tableRowClassName({
                rowIndex
            }) {
                if (0 == rowIndex % 2) {
                    return 'warning-row';
                } else {
                    return 'success-row';
                }
            },
        },
    }
</script>

<style>
    .serverlist.el-table .warning-row {
        background: #f5f7fa;
    }

    .serverlist.el-table .success-row {
        background: #ffffff;
    }

    .serverlist.el-table {
        margin-top: 16px;
    }

    .serverlist {
        font-size: 12px;
    }
</style>
